<template>
  <BCard body-class="gap-[32px] d-flex flex-column">
    <div class="text-black text-2xl font-semibold d-flex">
      <button
        class="button-primary text-white d-flex mr-1 px-[5px]"
        @click="$router.replace('/partner?tab=talent-pool')"
      >
        <span
          class="k-arrow-left-2 h-100 font-bold text-10"
          style="line-height: 1.5;"
        />
      </button>
      <span class="text-10 text--dark">Detail User</span>
    </div>
    <div class="flex items-center gap-[24px]">
      <img
        src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
        alt="Komerce"
        class="w-[40px] h-[40px] rounded-circle"
      >
      <div class="flex flex-column w-full gap-[12px]">
        <span class="text-muted">Nama</span>
        <span class="text-black font-semibold">{{ detailUser.full_name || '-' }}</span>
      </div>
      <div class="flex flex-column w-full gap-[12px]">
        <span class="text-muted">Email</span>
        <span class="text-black font-semibold">{{ detailUser.email || '-' }}</span>
      </div>
    </div>
    <div class="flex items-center gap-[24px] pb-2 border-b">
      <div class="flex flex-column w-[calc(100%+140px)] gap-[12px]">
        <span class="text-muted">Nomor Whatsapp</span>
        <span class="text-black font-semibold">+{{ detailUser.phone_number || '-' }} <button
          class="px-[8px] py-[2px] rounded bg-[#34A853] text-white"
          @click="followUp(detailUser.phone_number)"
        >Follow Up <span class="k-Whatsapp" /></button></span>
      </div>
      <div class="flex flex-column w-full gap-[12px]">
        <span class="text-muted">Sudah Follow Up?</span>
        <span class="text-black font-semibold">
          <b-form-checkbox
            v-model="detailUser.is_follow_up"
            :disabled="Boolean(detailUser.is_follow_up)"
            :value="1"
            @change="updateStatus()"
          >
            Sudah Follow Up
          </b-form-checkbox>
        </span>
      </div>
    </div>
    <div class="flex gap-[20px]">
      <label
        v-for="item, i in tabs"
        :key="i"
        :for="item"
        class="h-[40px] px-[12px] min-w-[192px] cursor-pointer select-none rounded-[26px] grid place-items-center capitalize [transition:letter-spacing_.3s] hover:tracking-wide bg-[#F4F4F4] text-[#222222] text-[16px]"
        :class="{ 'pointer-events-none !bg-[#34A853] text-white': tab === +i }"
      >
        {{ item.replaceAll('-', ' ') }}
        <input
          :id="item"
          type="radio"
          name="user-detail-tab"
          class="hidden"
          @change="tab = +i, $router.push(`/partner/${$route.params.id}/talent-pool?tab=${item}`)"
        >
      </label>
    </div>
    <section class="w-full overflow-hidden">
      <div
        class="w-full flex transition-transform will-change-transform duration-300"
        :style="{ transform: `translateX(calc(-${tab} * 100%))` }"
      >
        <div class="min-w-full">
          <h5 class="mb-1">
            Kuisioner
          </h5>
          <div class="d-flex flex-column flex-md-row gap-3 align-items-center w-100 pb-3 pb-md-5 border-bottom">
            <div class="d-flex flex-column gap-12 w-100">
              <div class="d-flex flex-column align-items-start gap-5 text-left">
                <div class="text-[#828282] col-12 p-0 d-flex">
                  <div class="col-1 p-0 text-[#34A853]">
                    1.
                  </div>
                  Minat pada layanan?
                </div>
                <span class="col-12 p-0 d-flex text-[#333333]">
                  <div class="col-1 p-0" />
                  {{ detailUser.field_interest || '-' }}
                </span>
              </div>
              <div class="d-flex flex-column align-items-start gap-5 text-left">
                <div class="text-[#828282] col-12 p-0 d-flex">
                  <div class="col-1 p-0 text-[#34A853]">
                    2.
                  </div>
                  Tipe Bisnis?
                </div>
                <span class="col-12 p-0 d-flex text-[#333333]">
                  <div class="col-1 p-0" />
                  {{ detailUser.business_type_name || '-' }}
                </span>
              </div>
              <div class="d-flex flex-column align-items-start gap-5 text-left">
                <div class="text-[#828282] col-12 p-0 d-flex">
                  <div class="col-1 p-0 text-[#34A853]">
                    3.
                  </div>
                  Sektor Bisnis Yang dijalankan?
                </div>
                <span class="col-12 p-0 d-flex text-[#333333]">
                  <div class="col-1 p-0" />
                  {{ detailUser.business_sector_name || '-' }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-column gap-12 w-100">
              <div class="d-flex flex-column align-items-start gap-5 text-left">
                <div class="text-[#828282] col-12 p-0 d-flex">
                  <div class="col-1 p-0 text-[#34A853]">
                    4.
                  </div>
                  Sudah berapa lama menjalakan bisnis di marketplace seperti shopee, tokopedia, bukalapak?
                </div>
                <span class="col-12 p-0 d-flex text-[#333333]">
                  <div class="col-1 p-0" />
                  {{ detailUser.marketplace_experience || '-' }}
                </span>
              </div>
              <div class="d-flex flex-column align-items-start gap-5 text-left">
                <div class="text-[#828282] col-12 p-0 d-flex">
                  <div class="col-1 p-0 text-[#34A853]">
                    5.
                  </div>
                  Sudah jalankan iklan seperti Facebook ads atau Google ads selama berapa lama ?
                </div>
                <span class="col-12 p-0 d-flex text-[#333333]">
                  <div class="col-1 p-0" />
                  {{ detailUser.ads_experience || '-' }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-full">
          <b-overlay
            id="scroll"
            :show="loading"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <b-table
              id="table"
              :items="items"
              :fields="tableListSavedCandidates"
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              show-empty
              sticky-header="500px"
            >
              <template #cell(id)="data">
                <div>{{ data.item.id }}</div>
              </template>
              <template #cell(name)="data">
                <div class="flex items-center gap-[8px]">
                  <b-avatar
                    size="40px"
                    class="avatar"
                    :src="data.item.image"
                  />
                  <span class="whitespace-nowrap">{{ data.item.name }}</span>
                </div>
              </template>
              <template #cell(role)="data">
                <div class="whitespace-nowrap">
                  {{ data.item.role }}
                </div>
              </template>
            </b-table>
          </b-overlay>
        </div>
      </div>
    </section>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { tableListSavedCandidates } from './config'

export default {
  data() {
    return {
      alertError,
      tableListSavedCandidates,
      detailUser: {},
      tabs: ['kuesioner', 'kandidat-disimpan'],
      tab: 0,
      loading: false,
      items: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async getDetailUser() {
      const url = `v1/talent_pool/user/${this.$route.params.id}/detail`
      this.loading = true
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailUser = await data
          this.items = data.candidates || []
        })
        .catch(err => {
          this.alertError(err)
        }).finally(() => {
          this.loading = false
        })
    },
    async initData() {
      const query = this.$route.query.tab
      await this.getDetailUser()
      if (!query || !this.tabs.includes(query) || query === this.tabs[0]) return
      this.tab = this.tabs.findIndex(t => t === query)
    },
    followUp(phone) {
      window.open(`https://wa.me/${phone}`, '_blank')
    },
    updateStatus() {
      const url = `v1/talent_pool/user/${this.$route.params.id}/change_follow_up`
      komtimAxiosIns
        .put(url)
        .then(async res => {
          this.getDetailUser()
        })
        .catch(err => {
          this.getDetailUser()
          this.alertError(err)
        })
    },
  },
}
</script>
